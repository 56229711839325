<template>
  <div>
    <left-and-right>
      <div slot="leftDiv">
        <h4>陕西西咸新区服务贸易协会章程</h4>
        <h5 style="margin-bottom: 50px">Article Of Association</h5>
        <div id="content" :style="{ 'max-height':maxHeight }" class="content">
          <h4>第一章 总则</h4>
          <p>
            第一条 本协会中文名称为“陕西西咸新区服务贸易协会”。英文名称为Shaanxi Xixian New Aera Association of Trade in
            Services(英文缩写：“SXXXATIS”)是经陕西省西咸新区改革创新发展局同意、陕西省西咸新区行政审批与政务服务局核准登记注册（统一社会信用代码：51610100MJU925023A），具有独立法人地位的非营利社会团体。
          </p>
          <p>
            第二条
            本会宗旨是:在遵守宪法、法律、法规和国家政策,践行社会主义核心价值观，遵守社会道德风尚，致力于成为促进陕西服务贸易发展的服务平台，沟通政府与企业，衔接国内与国外，整合各方面力量，维护企业利益，提高企业国际竞争力，树立陕西服务整体品牌，为企业开拓国际市场服务。
          </p>
          <p>
            第三条 本会根据中国共产党章程的规定，拟设立中国共产党的组织，开展党的活动，为党组织的活动提供必要条件。
          </p>
          <p>
            第四条 本会的登记管理机关是陕西省西咸新区行政审批与政务服务局。
          </p>
          <p>
            本会接受登记管理机关的监督管理和陕西省、西安市、区相关职能部门的业务指导。
          </p>
          <p>
            第五条 本会的活动地域为陕西省。
          </p>
          <p>
            第六条 本会的住所设在陕西省西咸新区秦汉新城秦汉文创大厦5层518室。
          </p>
          <h4>第二章 业务范围</h4>
          <p>
            第七条 本会的业务范围：
          </p>
          <p>
            （一）宣传贯彻国家关于国际服务贸易行业的方针、政策、法令、规定，按照陕西省人民政府关于促进服务贸易产业发展所制定的发展目标和政策规定，推进陕西省服务贸易产业发展。
          </p>
          <p>
            (二）搭建政企沟通桥梁。与省、市、区各级业务主管部门紧密联系，通过政策宣讲辅导培训、网络等多种形式帮助企业解读产业政策、用好政策；收集汇总行业企业发展中的问题及政策需求，形成具体的政策建议，以及在政府制定政策过程中形成导向性意见。

          </p>
          <p>
            （三）建立服务贸易各领域自律、协调机制，规范服务贸易企业行为，实现行业间资源共享、相互融合的发展环境；
            积极对外宣传陕西服务贸易企业的实力，塑造陕西服务贸易的整体品牌形象；
          </p>
          <p>
            （四）协助政府部门开展行业统计，加强服务贸易领域研究，掌握国内外行业发展动态，收集、发布行业信息，为政府制定政策和企业制定发展战略提供参考；
          </p>
          <p>
            （五）为企业做好国际国内市场拓展服务和维权服务。组织企业参加国际、国内知名服务贸易领域的展会，与国内外行业组织进行合作交流，举办推介会、研讨会等活动，帮助企业开拓国际国内两大市场。
          </p>
          <p>
            （六）接受政府部门和相关机构的委托，承担行业评估论证、技能资质考核、行业统计调查、行业发展规划，开展行业标准制定、企业资质认证等工作。
          </p>
          <p>
            （七）研究我省服务贸易的特点及发展难点，促进陕西服务贸易特色领域的发展，组织人才、技术、管理、法规等培训；
          </p>
          <p>
            （八）加强服务贸易平台建设。在政府职能部门监管下，通过多渠道、多载体的建设，搭建行业公共信息服务平台；成为云南服务贸易面向国际、国内市场宣传的行业窗口，并提供行业、市场等公共信息服务；
          </p>
          <p>
            (九) 接受职能部门授权、委托及购买服务的其它工作事项。
          </p>
          <h4>第三章  会员</h4>
          <p>
            第八条  本会的会员为单位会员和个人会员。单位会员是本会的主体，个人会员不超过会员总数的10%。单位会员由其法定代表人或主要负责人作为代表参加本会的活动。单位会员更换代表，需向协会书面报告。单位发生合并、分立、终止等情况时，应报协会备案，其会员资格相应变更或终止。
          </p>
          <p>
            第九条  符合下列条件的，可以申请加入本会：
          </p>
          <p>
            （一）拥护本会的章程；
          </p>
          <p>
            （二）有加入本会的意愿；
          </p>
          <p>
            （三）在服务贸易领域具有一定的影响。
          </p>
          <p>
            （四）单位会员为本行业的经济组织，应持有工商营业执照（法律有特别规定的，需提交相关许可证）等相关证件，在本行业领域内具有一定的影响；
          </p>
          <P>
            （五）个人会员为在本行业或相关行业内有较大影响力的专家和管理人员。
          </P>
          <p>
            第十条  会员入会的程序是：
          </p>
          <p>
            （一）提交入会申请书；
          </p>
          <p>
            （二）经两名以上会员介绍；
          </p>
          <p>
            （三）单位会员须提供法人资格证明文件的复印件、单位基本情况介绍等资料；个人会员须提交身份证明、个人工作简历等资料；
          </p>
          <P>
            （四）经理事会讨论通过；
          </P>
          <p>
            （五）由理事会或理事会授权的机构发给会员证。
          </p>
          <p>
            第十一条  会员享有下列权利：
          </p>
          <p>
            （一）选举权、被选举权和表决权；
          </p>
          <p>
            （二）对本会工作的知情权、建议权和监督权；
          </p>
          <p>
            （三）参加本会活动并获得本会服务的优先权；
          </p>
          <p>
            （四）参加本协会的活动；
          </p>
          <p>
            （五）入会自愿、退会自由。
          </p>
          <p>
            第十二条  会员履行下列义务：
          </p>
          <p>
            （一）遵守本会的章程和各项规定，执行本会的决议；
          </p>
          <p>
            （二）维护本会合法权益；
          </p>
          <p>
            （三）完成本会交办的工作；
          </p>
          <p>
            （四）按规定交纳会费；
          </p>
          <P>
            （五）向本会反映情况，提供有关资料；
          </P>
          <p>
            （六）积极宣传并参与本会的各项活动；
          </p>
          <p>
            （七）为本会的发展献策出力；
          </p>
          <p>
            （八）联络方式更改时及时通知本会；
          </p>
          <p>
            第十三条  会员如有违反法律法规和本章程的行为，经理事会表决通过，给予下列处分：
          </p>
          <P>
            （一）警告；
          </P>
          <p>
            （二）通报批评；
          </p>
          <p>
            （三）暂停行使会员权利；
          </p>
          <p>
            （四）除名。
          </p>
          <p>
            第十四条  会员退会须书面通知本会，并交回会员证。
          </p>
          <p>
            第十五条  会员有下列情形之一的，自动丧失会员资格或由本会给予除名：
          </p>
          <p>
            （一）一年不交纳会费；
          </p>
          <p>
            （二）两年不参加本会活动；
          </p>
          <p>
            （三）不再符合会员条件的；
          </p>
          <p>
            （四）丧失完全民事行为能力；
          </p>
          <p>
            （五）冒用本协会名义从事违法犯罪活动或给本会造成严重损害的。
          </p>
          <p>
            第十六条  会员退会、自动丧失会员资格或者被除名后，其在本会相应的职务、权利、义务自行终止。
          </p>
          <h4>第四章  组织机构</h4>
          <h5>第一节  会员大会</h5>
          <p>
            第十七条  会员大会是本会的最高权力机构，其职权是：
          </p>
          <p>
            （一）制订或修改章程；
          </p>
          <p>
            （二）选举或者罢免会长、副会长、理事、监事；
          </p>
          <p>
            （三）审议理事会、监事会的工作报告、财务预决算方案；
          </p>
          <p>
            （四）制定和修改会费标准；
          </p>
          <p>
            （五）制定和修改选举办法；
          </p>
          <p>
            （六）改变或者撤销理事会不适当的决定；
          </p>
          <P>
            （七）审议本会发展的重大事项；
          </P>
          <p>
            （八）决定终止事宜；
          </p>
          <p>
            （九）决定其他重大事宜。
          </p>
          <p>
            第十八条  会员大会每两年至少召开1次，每届5年。
            因特殊情况需提前或延期换届的，须由理事会表决通过，报经社团登记管理机关批准同意。但延期换届最长不超过1年。
          </p>
          <p>
            召开会员大会，须提前7日将会议的议程书面通知会员。
            会员大会不得采用通讯表决方式。
          </p>
          <p>
            第十九条  有1/5以上的会员或者理事会提议，须召开临时会员大会。
          </p>
          <p>
            第二十条  会员大会须有2／3以上的会员出席方能召开，其决议或决定须经全体会员的过半数表决通过，方能生效。
          </p>
          <p>
            第二十一条  下列事项，须以无记名方式表决，并经全体会员的过半数表决通过：
          </p>
          <p>
            （一）制定和修改章程；
          </p>
          <p>
            （二）选举和罢免理事、会长、副会长。
          </p>
          <p>
            （三）制定或修改会费标准；
          </p>
          <h5>
            第二节  理事会
          </h5>
          <P>
            第二十二条  理事会是会员大会的执行机构，负责行业
            协会日常工作，对会员大会负责。
          </P>
          <p>
            理事会由会长、副会长、秘书长、理事组成。
          </p>
          <p>
            理事会的人数不得超过会员总数的三分之一。
          </p>
          <p>第二十三条  理事的产生程序：</p>
          <P>（一）第一届理事由发起人提名，会员大会选举产生；</P>
          <p>
            （二）理事会换届，由上一届理事会提名新一届理事候选人，会员大会选举产生；
          </p>
          <p>第二十四条  单位理事的代表由该单位的主要负责人担任。单位调整理事代表，由其书面通知本会，报理事会备案。</p>
          <p>
            第二十五条  理事会的职权是：
          </p>
          <p>
            （一）执行会员大会的决议；
          </p>
          <p>
            （二）聘任或者解聘秘书长；
          </p>
          <P>（三）决定名誉职务的设立和人选；</P>
          <p>
            （四）筹备召开会员大会；
          </p>
          <p>
            （五）向会员大会报告工作和财务状况；
          </p>
          <p>
            （六）决定办事机构、分支机构、代表机构和实体机构的设立、变更和注销；
          </p>
          <p>
            （七）决定副秘书长、各办事机构主要负责人的人选；
          </p>
          <p>
            （八）领导本会各机构开展工作；
          </p>
          <p>
            （九）审议年度工作报告和工作计划；
          </p>
          <p>
            （十）审议年度财务预算、决算；
          </p>
          <p>
            （十一）制定内部管理制度；
          </p>
          <p>
            第二十六条  理事会每届5年。理事会与会员大会届期相同，与会员会届期同时换届。因特殊情况需提前或者延期换届的，由理事会表决通过，报登记管理机关同意。延期换届最长不超过1年。
          </p>
          <p>
            第二十七条  理事会会议应当有三分之二以上的理事
            会人员出席方可举行，其做出的决议或者决定应当经全体理事会人员的过半数表决通过。
          </p>
          <p>
            第二十八条  理事会每年至少召开一次会议。召开理事
            会，须提前7日将会议的议程书面通知理事。理事3次不出席理事会会议，自动丧失理事资格。
          </p>
          <p>
            第二十九条  三分之一的理事提议，须召开临时理事会
            会议。
          </p>
          <h5>
            第三节  负责人
          </h5>
          <p>
            第三十条  本会的负责人包括：会长、副会长和秘书长
            本会负责人须具备下列条件：
          </p>
          <p>
            （一）在本会业务领域内有较大影响，有从事本行业3年以上经历，熟悉行业情况，在行业内有良好声誉；
          </p>
          <p>
            （二）最高任职年龄不超过70周岁；
          </p>
          <p>
            （三）身体健康，能坚持正常工作；
          </p>
          <p>
            （四）未受过剥夺政治权利的刑事处罚的；
          </p>
          <p>
            （五）具有完全民事行为能力；
          </p>
          <p>
            第三十一条  本会会长、副会长、秘书长如超过最高任职年龄的，须经理事会表决通过，报社团登记管理机关批准同意后，方可任职。会长、秘书长不得由同一会员单位的人员担任，不得是近亲属，或者具有其他利害关系。会长不得兼任秘书长。
          </p>
          <p>
            第三十二条  会长为本会法定代表人。本会会长不兼任其他社会团体的法定代表人。
          </p>
          <p>
            第三十三条  本会会长行使下列职权：
          </p>
          <P>
            （一）代表本会签署有关重要文件；
          </P>
          <p>
            （二）召集和主持理事会；
          </p>
          <p>
            （三）检查会员大会、理事会决议的落实情况；
          </p>
          <p>
            第三十四条  本会秘书长协助会长工作，秘书长为专职，行使下列职权：
          </p>
          <p>
            （一）主持办事机构开展日常工作，组织实施年度工作计划；
          </p>
          <p>
            （二）协调各分支机构、代表机构、实体机构开展工作；
          </p>
          <p>
            （三）提名副秘书长以及各办事机构、分支机构、代表机构和实体机构主要负责人，交理事会决定；
          </p>
          <p>
            （四）决定办事机构、代表机构、实体机构专职工作人员的聘用；
          </p>
          <p>
            （五）处理其他日常事务。
          </p>
          <p>
            第三十五条  会员大会、理事会、负责人会议应当制作会议记录。形成决议的，应当制作书面决议，并由负责人审阅、签名。会议记录、会议决议应当以适当方式向会员通报，并保存年十年。
          </p>
          <p>
            临时会员大会、临时理事会会议，均不得研究提议议题之外的事项。
          </p>
          <h5>
            第四节  办事机构和分支机构、代表机构
          </h5>
          <p>
            第三十六条  办事机构是理事会领导下，授权秘书长具体负责的本会内设机构。
          </p>
          <p>
            第三十七条  本会分支机构、代表机构的设立、变更和注销，经理事会讨论通过后，报登记管理机关备案。
          </p>
          <p>
            第三十八条  分支机构、代表机构不具有法人资格，须严格遵守本会章程，接受本会领导，执行本会决议。对外开展活动时，必须冠以本会的全称，其简称和译名由理事会规定。分支机构不得另起其他名称，不得另订章程、标识。
          </p>
          <h5>
            第五节  监事会
          </h5>
          <p>
            第三十九条  本会设立监事会，由2名监事组成，监事任期与理事任期相同，期满可以连任。
            监事会设监事长1名，由监事会推举产生。
          </p>
          <p>
            第四十条  监事的产生和罢免：
          </p>
          <p>
            (一)由会员大会选举产生；
          </p>
          <p>
            （二）监事的罢免依照其产生程序。
          </p>
          <p>
            第四十一条  会长、副会长、秘书长、理事及其近亲属不得兼任监事。
          </p>
          <p>
            第四十二条  监事会行使下列职权：
          </p>
          <p>
            （一）列席会员大会，列席理事会，监督会员、理事会会议议题程序和表决的合法性；监督理事会遵守法律和章程的情况，当其行为损害本会以及公共利益时，要求其予以纠正；
          </p>
          <P>
            （二）审查本会财务报告，向会员大会报告监事会的工作；
          </P>
          <p>
            （三）提出质询和建议，并向登记管理机关、行业管理部门和其他有关部门反映情况。
          </p>
          <p>
            （四）决定其他应由监事会审议的事项。
          </p>
          <p>
            第四十三条  监事会每6个月至少召开1次会议。监事会会议须有三分之二以上监事出席方能召开，其决议须经全体监事半数以上通过方为有效。
          </p>
          <p>
            第四十四条  监事应当遵守有关法律法规和本会章程，忠实履行职责。
          </p>
          <h5>
            第五章  资产管理、使用原则
          </h5>
          <p>
            第四十五条  本会经费来源：
          </p>
          <P>
            （一）会费；
          </P>
          <p>
            （二）捐赠；
          </p>
          <p>
            （三）政府资助；
          </p>
          <p>
            （四）在核准的业务范围内开展活动或者服务的收入；
          </p>
          <p>
            （五）利息；
          </p>
          <p>
            （六）其他合法收入。
          </p>
          <p>
            第四十六条  本会经费主要用于：
          </p>
          <p>
            （一）本章程规定的业务范围和事业的发展；
          </p>
          <p>（二）必要的行政办公和人员薪酬支出；</p>
          <P>
            （三）其他由理事会决定的事项。
          </P>
          <p>第四十七条  本会执行《民间非营利组织会计制度》，
            建立严格的财务管理制度，保证会计资料合法、真实、准确、完整。</p>
          <p>
            第四十八条  本会配备具有专业资格的会计人员。会计不得兼任出纳。会计人员必须进行会计核算，实行会计监督。会计人员调动工作或者离职时，必须与接管人员办清交接手续。
          </p>
          <p>
            第四十九条  本会的资产管理必须执行国家规定的财务管理制度，接受会员大会和有关部门的监督。资产来源属于国家拨款或者社会捐赠、资助的，必须接受审计机关的监督，并将有关情况以适当方式向社会公布。
          </p>
          <P>
            第五十条  本会换届或者更换法定代表人之前必须进行财务审计。
          </P>
          <p>
            第五十一条  本会的全部资产为本会所有，任何单位、个人不得侵占、私分和挪用，也不得在会员中分配。
          </p>
          <p>
            第五十二条  本会建立专职人员聘任及其管理制度，其工资和保险、福利待遇，参照国家及省对事业单位的有关规定执行。
          </p>
          <h5>第六章  章程的修改程序</h5>
          <p>
            第五十三条  对本会章程的修改，由理事会表决通过后，提交会员大会审议。
          </p>
          <p>
            第五十四条  本会修改的章程，经会员大会到会会员表决通过后15日内，报民政厅核准后生效。
          </p>
          <h5>第七章  终止程序及终止后的财产处理</h5>
          <p>第五十五条  本会终止动议由理事会提出，经会员大会表决通过。</p>
          <p>第五十六条  本会在有关部门指导下成立清算组织，清理债权债务，处理善后事宜。清算期间，不开展清算以外的活动。</p>
          <p>第五十七条  本会经登记管理机关办理注销登记手续后即为终止。</p>
          <p>第五十八条  本会终止后的剩余财产，在登记管理机关的监督下，按照国家有关规定，用于发展与本会宗旨相关的事业。</p>
          <h4>第八章  附则</h4>
          <p>第五十九条  本章程经2020年9 月25 日一届一次会员代表大会表决通过。</p>
          <p>第六十条  本章程的解释权属本会的理事会。</p>
          <p>第六十一条  本章程自登记管理机关核准之日起生效。</p>
        </div>
        <button v-if="maxHeight!=''" @click="open">查看更多</button>
      </div>
      <div slot="rightDiv">
        <card ref="card"></card>
        <img src="../../assets/image/web.png">
      </div>
    </left-and-right>
  </div>
</template>

<script>
import leftAndRight from "@/components/public/leftAndRight";
import card from "@/components/public/card";

export default {
  name: "constitution",
  components: {
    leftAndRight,
    card
  },
  data() {
    return {
      maxHeight:'1010px',

    }
  },
  mounted() {
    this.createdList()
  },
  methods: {
    createdList() {
      let content = []
      let p = document.getElementById('content')

      let elements = p.childNodes

      let len = elements.length;

      for (let i = 0; i < len; i++) {
        let element = elements[i]
        let tagName = element.tagName
        let map = {}
        if (tagName === 'H4') {
          console.log("h4"+content.length)
          map.id = content.length
          element.setAttribute('id', 'H4' + content.length)
          map.to = '#H4' + content.length
          map.title = element.innerHTML;
          map.content = []
          content.push(map)
        }
        let child = {}
        if (tagName === 'H5') {
          let i = content.length
          child.title = element.innerHTML
          child.id = content[i - 1].content.length
          element.setAttribute('id', 'H5' + content[i - 1].content.length)
          child.to = '#H5' + content[i - 1].content.length
          content[i - 1].content.push(child)
        }

        element = element.nextSibling

      }
      let card = []
      let one = {}
      one.flag=true
      one.title = '章程目录'
      one.content = content
      card.push(one)

      this.$nextTick(()=>{this.$refs.card.init(card)})
    },
    open(){
     this.maxHeight=''

    }
  }
}
</script>

<style scoped>
img {
  width: 100%;
}

h4, h5 {
  margin: 10px 0;
}
#content{
  overflow: hidden;
  margin-bottom: 20px;
}

p {
  color: #666666;
  font-size: 14px;
  line-height: 25px;
  text-indent: 2em;
}

strong {
  color: #050001
}

button {
  width: 100%;
  height: 50px;
  border-radius: 4px;
  background: #eeeeee;
  border: 1px solid #dfdfdf;
}
</style>