<template>
  <div>
    <div class="information">
      <slot name="heard"></slot>
      <div v-for="value in val" :key="value.index">
        <h4><span></span>{{ value.title }}</h4>
        <ul :style="{listStyle: listStyle}">
          <li v-for="item in value.content" :key="item.id" @click="showUl(item)">
            <p> <a :href="item.to">{{ item.title }}</a></p>
            <ul v-show="show==item.id" v-if="item.content!==null">
              <li v-for="item1 in item.content" :key="item1.id">
                <p><a :href="item1.to">{{ item1.title }}</a></p>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "card",
  props: {
    listStyle: {}
  },
  data() {
    return {
      show: -1,
      val:null
    }
  },
  methods: {
    init(val){
      this.val=val
    },
    showUl(e) {
      if(this.val[0].flag){
        if(this.show==e.id){
          this.show=-1
        }else {
          this.show=e.id
        }
      }else {
        this.$router.push('/newsDetails?id='+e.id)
      }
    }
  }
}
</script>

<style scoped>
.information {
  background: #f8f8f8;
  padding: 30px 18px;
  margin-bottom: 10px;
  border: 1px solid #dfdfdf;
}

.information ul li p {
  line-height: 24px;
  font-size: 14px;
  color: #444444;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  cursor: pointer;
  cursor: hand;
}

h4 {
  color: #333333;
}
a{
  color: #333333;
  text-decoration: none;
}

span {
  display: inline-block;
  width: 3px;
  height: 12px;
  background: red;
  margin-right: 5px;
}
</style>